import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import { Login } from 'models'
class AuthService {

  static login = (form: {email: string, password: string}): Promise<Login> => {
    return new Promise((resolve, reject) => {
      axios
      .post('auth/login', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
  AuthService
};
