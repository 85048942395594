import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import { PlanPrevItems, Provider, Plan, Coverage } from 'models'
const END_POINT = 'admin/comun-coverages'
class ComunCoverageService {

  static get = (): Promise<PlanPrevItems[]> => {
    return new Promise((resolve, reject) => {
      axios
      .get(END_POINT)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static getProvidersWithPlans = (): Promise<{insurances: Provider[], plans: Plan[]}> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}/get-providers-with-plans`)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static save = (values: any): Promise<{msg: string}> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}/save`, {values})
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
  ComunCoverageService
};
