import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import { DefaultResponse, Provider } from 'models'
const END_POINT = 'admin/providers'
class ProviderService {

  static get = (): Promise<Provider[]> => {
    return new Promise((resolve, reject) => {
      axios
      .get(END_POINT)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static update = (form: Provider): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .put(END_POINT, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static create = (form: Provider): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post(END_POINT, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static delete = (form: Provider): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .delete(END_POINT+'/'+form.id)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
  ProviderService
};
