import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import { ResponseWithPagination, Coverage, DefaultResponse, Plan, PlanPrevItems } from 'models'
const END_POINT = 'admin/plans'
type Form = {
  provider_id: string | number,
  name: string,
  id?: number,
  northAmerica: 'y' | 'n' | null,
  southAmerica: 'y' | 'n' | null,
  europe: 'y' | 'n' | null,
  world: 'y' | 'n' | null,
  centralAmerica: 'y' | 'n' | null,
  nacional: 'y' | 'n' | null,
  age_limit: number,
  day_limit_condition: string,
  day_limit: string,
  items: Coverage[],
  travelDays: number,
  dayPrice: number,
  prices: number[],
  planPrevItems: Array<PlanPrevItems & {value?: string}>
}
class PlanService {
  static get = (page: number): Promise<ResponseWithPagination> => {
    return new Promise((resolve, reject) => {
      axios
      .get(`${END_POINT}?page=${page}`)
      .then(
        (response: AxiosResponse) =>
          resolve({
            data: response.data.data,
            pagination: {
              ...response.data
            }
          }),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static getPrevItems = (): Promise<PlanPrevItems[]> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}/get-prev-items`)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static save = (form: Form): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}`, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static update = (form: Form): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .put(`${END_POINT}`, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static delete = (plan: Plan): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .delete(`${END_POINT}'/'${plan.id}`)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
  PlanService
};
