import React from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import { CustomProps } from "models";
import Icon from "./Icon";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

registerLocale("es", es);
setDefaultLocale("es");

const CustomInput = React.forwardRef<HTMLDivElement, CustomProps>(
  ({ color, value, text, onClick }, ref) => (
    <div
      ref={ref}
      className={`container-datepicker ${color ? color : ""}`}
      onClick={onClick}
    >
      <p>{value ? value : text ? text : ""}</p>
      {!value && <p>{text}</p>}
      <Icon name="calendar" />
    </div>
  )
);

interface DateProps {
  inline?: boolean;
  required?: boolean;
  label?: string;
  color?: string | "";
  value?: Date;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  text?: string;
  onChange(
    date: Date | null,
    event: React.SyntheticEvent<any> | undefined
  ): void;
  highlightDates?: [];
  dateFormat?: string;
  showYearDropdown?: boolean
  showMonthDropdown?: boolean
  popperPlacement?: string;
  placeholder?: string
  labelColor?: string
}

const _DatePicker: React.FC<DateProps> = ({
  label,
  required = false,
  color,
  minDate,
  maxDate,
  value,
  disabled = false,
  text,
  onChange,
  inline = false,
  highlightDates,
  dateFormat = 'dd-MM-yyyy',
  showYearDropdown = false,
  showMonthDropdown = false,
  popperPlacement = 'end',
  placeholder,
  labelColor = false
}) => (
  <div className="form-group">
    {label && (
      <label className={"label-datepicker "+(labelColor || 'text-dark-gray')}>
        {label} {required ? <span className={labelColor || 'text-dark-gray'}>(*)</span> : ''}
      </label>
    )}
    <DatePicker
      highlightDates={highlightDates}
      inline={inline}
      minDate={minDate}
      maxDate={maxDate}
      dateFormat={dateFormat}
      selected={value}
      onChange={onChange}
      customInput={<CustomInput value={value} color={color} text={text} />}
      disabled={disabled}
      showYearDropdown={showYearDropdown}
      showMonthDropdown={showMonthDropdown}
      popperPlacement={popperPlacement}
      placeholderText={placeholder}
    />
  </div>
);

export default _DatePicker;
