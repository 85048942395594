import React from 'react'
import Modal from 'react-modal'
import Icon from './Icon'
interface Props {
    children: JSX.Element
    visible: boolean,
    onClose?: () => void,
    title?: string,
    styles?: {
        content?: React.CSSProperties;
        overlay?: React.CSSProperties;
    },
    id?: string
}
const _Modal = (props: Props) => {
    return(
        <Modal
            isOpen={props.visible}
            onRequestClose={props.onClose}
            contentLabel={props.title}
            style={props.styles}
            ariaHideApp={false}
            id={props.id}
        >
            {
                props.onClose && (
                    <div className="modal-close-container w-100 text-right">
                        <Icon
                            style={{
                                position: 'absolute',
                                top: '0'
                            }}
                            name="close cursor-pointer"
                            onClick={() => props.onClose?.()}
                        />
                    </div>
                )
            }
            {props.children}
        </Modal>
    )
}

export default _Modal