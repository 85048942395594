const STATUS_ACTIVE = 1
const STATUS_INACTIVE = 0
const WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=+14072146098&text=Tengo%20unas%20preguntas%20para%20Compara%20Assist'
const CALENDLY_LINK = 'https://calendly.com/comparaassist/asesoria'
const WHATSAPP_PHONE = '+ 1 407- 214-6098'
const BLOG_LINK = process.env.GATSBY_BLOG;//AquiModifique'https://blog.comparaassist.com'
const ASSISNET_CARD_LINK = 'https://dev.comparaassist.com//files/contrats/AssistNetCard.pdf'
const DESTINIES = [
    {
        en: 'North America',
        slug: 'northAmerica',
        es: 'Norte américa',
    },
    {
        en: 'Europe',
        slug: 'europe',
        es: 'Europa',
    },
    {
        en: 'World',
        slug: 'world',
        es: 'Mundo',
    },
    {
        en: 'South America',
        slug: 'southAmerica',
        es: 'Sur américa',
    },
    {
        en: 'Central America',
        slug: 'centralAmerica',
        es: 'América central',
    },
    {
        en: 'National',
        slug: 'nacional',
        es: 'Nacional',
    },
]
export {
    DESTINIES,
    ASSISNET_CARD_LINK,
    BLOG_LINK,
    WHATSAPP_PHONE,
    CALENDLY_LINK,
    WHATSAPP_LINK,
    STATUS_ACTIVE,
    STATUS_INACTIVE
}