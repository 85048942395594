import axios, {AxiosError, AxiosResponse ,AxiosInstance} from 'axios';
import {emit} from 'jetemit'
import { Login as User } from 'models';
const baseURL = process.env.GATSBY_API_URL;

let _axios: AxiosInstance = axios.create({
  baseURL,
});
_axios.interceptors.request.use(
  (config) => {
    const user: User = emit('get-user')[0];
    if (user && user.access_token) {
      config.headers["Authorization"] = `Bearer ${user.access_token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if(response.data.status === 'error'){
      const keys: string[] = Object.keys(response.data[0])
      if(keys.length > 1){
        const message: string = response.data[0]?.[keys[0]]?.[0]
        if(message)
          throw {
            validations: response.data[0],
            message,
            fromInterceptor: true
          }
      }
    }
    return response;
  }
);
export default _axios;