import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import { DefaultResponse, Plan } from 'models'
const END_POINT = 'admin/invoices'
type Form = {
    since: Date,
    until: Date | undefined,
    destiny_id: string,
    passengers_ages: number[]
}
type PlanSubmitPlan = {plan: Plan, coin: string, price: number}
type SubmitPlan = {
  email: string,
  form: Form,
  plans: PlanSubmitPlan[] | null,
}
class InvoiceService {

  static get = (form: Form): Promise<Plan[]> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}/get`, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static save = (form: SubmitPlan): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}`, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static sendSale = (form: SubmitPlan): Promise<DefaultResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post(`${END_POINT}/send-sale`, form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
  InvoiceService
};
